.btn {
    border-radius: 10px;
    font-size: 13px;
    font-weight: 700;
    height: 40px;
    min-width: 130px;
    line-height: 28px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.btn-medium {
        min-width: 160px;
        height: 48px;
        line-height: 36px;
    }

    &.btn-lg {
        min-width: 172px;
        height: 50px;
        border-radius: 5px;
    }

    &.btn-xl {
        min-width: 180px;
        height: 60px;
        border-radius: 5px;
    }

    &.btn-mini {
        min-width: 80px;
        height: 30px;
        border-radius: 5px;
    }

    &.with-icon {

        i {
            margin-left: -5px;
            margin-right: 3px;
            font-size: 16px;
            margin-top: 2px;
        }
    }

    &-reset {
        background: transparent;
        border: 0;
    }
}

// Bookmark

.bookmark-button{
   &:before{
     content:"\f02e";
     color: inherit;
     font-size: inherit;
     font-weight: 400;
     font-family:"Font Awesome 5 Free";
     display: inline-block;
   }
   &:hover,&.clicked{
     &:before{
       font-weight: 900;
       color: $green;
     }
   }
}