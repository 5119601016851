.modal.show {
  .modal-dialog{
    pointer-events: visible;
  }
}

@media only screen and (min-height: 775px) {
  .form-modal.modal{

    align-items: center;
    display: flex!important;
    pointer-events: none;
    &.show {
      
      pointer-events: visible;
    }
  }
    //.modal-dialog {
     
           // margin-top: auto !important;
      // }
     
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {

    .form-modal.modal {
        align-items: center;
        display: flex !important;
        pointer-events: none;

        &.show {
            pointer-events: visible;
        }
    }

    .modal-dialog {
       
            margin-top: 80px;
       
    }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

    .form-modal.modal {
        align-items: center;
        display: flex !important;
        pointer-events: none;

        &.show {
            pointer-events: visible;
        }
    }

    .modal-dialog {
        margin-top: 80px;
    }
}