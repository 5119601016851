// Temporary custom css added.. I will remove later @omi mama...
::-webkit-input-placeholder { /* Edge */
    color: red;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: red;
}

::placeholder {
    color: red;
}
.btn-h-60 {
    // this class will remove from here later when "Omi mama" will create new size... used in cta section button
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btn-hover {
    &:hover {
        color: $green;
        background-color: transparent;
        border: 2px solid $green !important;
        box-shadow: $shadow-5;
    }
}

.captcha {
    &:focus {
        border: 0;
    }

    &:click {
        border: 0;
    }
}

.error {
    color: $red;
}

.form-control-error {
    border-color: $red;
}

input[type='password'] {
    font-weight: bolder;
    letter-spacing: 0.125em;
}

.form-control {
    color: black;
}

.bg-green-2 {
    background-color: $link-hover-color;
}

.text-green-2 {
    color: $link-hover-color;
}

.table-responsive {
    padding-left: 1px;
    padding-right: 1px;
}
/* ----------------------------
  Hero Form 1
------------------------------*/
.filter-search-form-1 {
    .nice-select {
        .current {
            width: calc(100% - 35px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.2;
        }

        .list {
            min-width: 290px;
        }
    }
}
/* ----------------------------
  Search list 1
------------------------------*/
.filter-search-form-2.search-1-adjustment,
.search-filter.search-1-adjustment {
    .nice-select {
        .current {
            width: calc(100% - 35px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.2;
        }

        .list {
            min-width: 290px;
        }
    }
}
// masonry css added newly for responsive
.card-columns {
    // used in Landing-3 content-2 section masonry
    @include media-breakpoint-only(lg) {
        column-count: 4;
    }

    @include media-breakpoint-only(xl) {
        column-count: 5;
    }
}
// masonry css added newly for responsive
 

//How it works
.howitworks-slider-one {
    position: relative;
    padding-left: 5px;

    .slick-next {
        border-radius: 0 5px 5px 0;
        background-color: $green;
        height: 80px;
        width: 30px;
        right: -33px;

        @include desktops {
            height: 100px;
            width: 45px;
            border-radius: 0 5px 5px 0;
            right: 0px;
        }

        @include tablet {
            height: 100px;
            width: 45px;
            border-radius: 0 5px 5px 0;
            right: -50px;
        }
    }


    .slick-prev {
        border-radius: 5px 0 0 5px;
        background-color: $green;
        height: 80px;
        width: 30px;
        left: -30px;
        margin-right: 5px;

        @include desktops {
            height: 100px;
            width: 45px;
            border-radius: 5px 0 0 5px;
            left: -60px;
        }



        @include tablet {
            height: 100px;
            width: 45px;
            border-radius: 5px 0 0 5px;
            left: -45px;
        }
    }

    .slick-prev:before, .slick-next:before {
        font-family: 'slick';
        font-size: 25px;
        line-height: 1;
        opacity: .75;
        color: white;

        @include desktops {
            font-size: 40px;
        }



        @include tablet {
            font-size: 40px;
        }
    }

    .slick-prev:hover, .slick-next:hover {
        background: $green-2;
    }

    .slick-dots {
        position: absolute;
        left: 0;
        bottom: -50px;
        padding: 0;
        margin: 0 auto;
        text-align: center;
        width: 100%;

        li {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: rgba( $black, 0.5);
            margin: 3px;
            border-radius: 50%;
            transition: 0.4s;

            &.slick-active {
                background-color: $green;
            }

            button {
                opacity: 0;
            }
        }
    }
}
// Testimonial
.testimonial-slider-one {
    position: relative;

    .slick-dots {
        position: absolute;
        left: 0;
        bottom: -68px;
        padding: 0;
        margin: 0 auto;
        text-align: center;
        width: 100%;

        li {
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: rgba($white, 0.13);
            margin: 3px;
            border-radius: 50%;
            transition: 0.4s;

            &.slick-active {
                background-color: $green;
            }

            button {
                opacity: 0;
            }
        }
    }
}
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Hero Area Custom Styles
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.hero-image-positioning-2 {
    @include desktops {
        transform: translateX(40%) scale(0.7);
    }

    @include large-desktops {
        transform: translateX(50%) scale(1);
    }

    .single-image {
        overflow: hidden;
        border-radius: 8px;

        @include desktops {
            max-height: 700px;
        }

        img {
            width: 100%;

            @include desktops {
                width: auto;
                height: 100%;
            }
        }
    }
}
// custom css added for rotate an item
.rotate-n10 {
    transform: rotate(-10deg);
}
// custom css Added for border
.border-10 {
    border-style: solid;
    border-width: 10px;
}

.border-1-green {
    border-style: solid;
    border-width: 1px;
    border-color: $green;
}

.border-1-gray {
    border-style: solid;
    border-width: 1px;
    border-color: $hit-gray;
}

.border-1-red {
    border-style: solid;
    border-width: 1px;
    border-color: $red;
}

.border-1-mercury {
    border-style: solid;
    border-width: 1px;
    border-color: $mercury;
}

.border-hit-gray-opacity-5 {
    border-color: rgba($hit-gray, 0.5);
}
// Landing-2 Pricing Section Recomended pricing's border top custom css is here
.recomended-pricing {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        left: 2px;
        top: 0px;
        width: 99%;
        height: 5px;
        background: #336ef7;
        border-radius: 0px 0px 10px 10px;
    }
}
/* Featured Card 3 title exerpt */
.f03-title-exerpt {
    white-space: nowrap;
    width: 242px;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* Gallery Image */
.gallery {
    display: flex;
    flex-wrap: wrap;

    .single-item {
        max-width: 20%;
        width: 20%;
        // @include tablet{
        //   max-width: 10%;
        //   width: 10%;
        // }
        // @include desktops{
        //   max-width: 20%;
        //   width: 20%;
        // }
        img {
            width: 100%;
        }
    }
}
/* OR devider */
.or-devider {
    text-align: center;
    position: relative;
    z-index: 1;

    &:before {
        content: "";
        height: 1px;
        width: 100%;
        top: 50%;
        left: 0%;
        margin-top: 1.5px;
        position: absolute;
        background: var(--border-color);
        z-index: -1;
    }

    span {
        display: inline-block;
        background: white;
        padding: 15px 25px;
    }
}

.message-devider {
    text-align: center;
    position: relative;
    z-index: 1;

    &:before {
        content: "";
        height: 1px;
        width: 100%;
        top: 50%;
        left: 0%;
        margin-top: 1.5px;
        position: absolute;
        background: gray;
        opacity:0.5;
        z-index: -1;
    }

    span {
        display: inline-block;
        background: white;
        padding: 15px 25px;
    }
}

.or-vertical-devider {
    position: relative;
    z-index: 1;

    &:before {
        text-align: center;
        content: "";
        height: 95%;
        width: 2px;
        top: 10px;
        bottom: 0px;
        left: 50%;
        margin-top: 1.5px;
        position: absolute;
        background: var(--border-color);
        z-index: -1;
    }
}
/*/------ Dashboard Adjustment Styles ------/*/
.dashboard-main-container {
    @include tablet {
        padding-left: 290px;
    }

    @include desktops {
        padding-left: 290px;
        padding-right: 25px;
    }

    @include extra-large-desktops {
        max-width: calc(100% - 290px);
        margin-left: auto;
        padding-left: 25px;
        padding-right: 25px;
    }
}
// Checkbox
.checkbox-bg-primary {
    input[type="checkbox"] + label:before {
        background-color: red;
    }
}

.search-filter {
    .nice-select .list {
        width: auto;
    }
}
// Bar Chart
.slider-price {
    input {
        background: transparent;
        border: transparent;
        color: #1a1f2b;
        text-align: left;
        word-spacing: 0;
    }
}
// Range bar
.ui-slider-horizontal {
    margin-top: 5px;
    height: 5px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
    border: none;
    background: #fff;
    font-weight: normal;
    color: #454545;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    outline: none;
}

.ui-slider-horizontal .ui-slider-handle {
    top: -10px;
}

.ui-widget.ui-widget-content {
    border: none;
}

.pm-range-slider {
    min-width: 290px;
    max-width: 290px;
    background: $conch;

    .ui-widget-header {
        background: $primary;
        top: 0;
        height: 100%;
    }
}

.graph {
    min-width: 290px;
    max-width: 290px;
    left: 0;
    top: 14px;

    @include desktops {
        // left: -55px;
        top: 12px;
    }

    @include large-desktops {
        // left: -30px;
        top: 16px;
    }

    span {
        width: 16px;
        height: 60px;
        background-color: $conch;

        &:nth-child(1) {
            height: 10px;
        }

        &:nth-child(2) {
            height: 14px;
        }

        &:nth-child(3) {
            height: 14px;
        }

        &:nth-child(4) {
            height: 19px;
        }

        &:nth-child(5) {
            height: 27px;
        }

        &:nth-child(6) {
            height: 41px;
        }

        &:nth-child(7) {
            height: 50px;
        }

        &:nth-child(8) {
            height: 37px;
        }

        &:nth-child(9) {
            height: 60px;
        }

        &:nth-child(10) {
            height: 27px;
        }

        &:nth-child(11) {
            height: 19px;
        }

        &:nth-child(12) {
            height: 27px;
        }

        &:nth-child(13) {
            height: 14px;
        }
    }
}

.overflow-y-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
}

.overflow-scroll {
    overflow-y: auto;
    overflow-x: hidden;
}
// Scroll Bar
* {
    scrollbar-width: thin;
    scrollbar-color: $mercury;
}

*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: $mercury;
    border-radius: 46px;
}

.active-item-black {
    color: $primary;
}

.result-view-type {
    a {
        color: map-get($theme-colors, "hit-gray");

        &.active {
            color: map-get($theme-colors, "black-2");
        }
    }
}
/* Step devider */
.step-devider {
    text-align: center;
    position: relative;
    z-index: 1;

    &:before {
        text-align: center;
        content: "";
        height: 1px;
        width: 70%;
        top: 50%;
        left: 13%;
        margin-top: 1.5px;
        position: absolute;
        background: var(--border-color);
        z-index: -1;
    }



    span {
        display: inline-block;
        background: white;
        padding: 15px 25px;
    }
}

.step-vertical-devider {
    text-align: center;
    position: relative;
    z-index: 1;



    &:before {
        text-align: center;
        content: "";
        height: 80%;
        width: 0px;
        top: 15%;
        bottom: 20%;
        left: 50%;
        margin-top: 1.5px;
        position: absolute;
        background: var(--border-color);
        z-index: -1;
    }

    span {
        display: inline-block;
        background: white;
        padding: 15px 25px;
    }
}
.showtop {
    z-index: 9999;
}
.showbottom {
    z-index: 1;
}
.scroll-up {
    position: fixed;
    bottom: 7rem;
    right: 1rem;
    z-index: 2055;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;

    &:hover {
        background-color: $white;
        box-shadow: $shadow-5;
    }
}

.pointer {
    cursor: pointer;
}

.cookie-overlay {
    position: fixed;
    bottom: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 2051;
    font-size: 14px;
    border-radius: 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    width: 100%;

    > div:first-child {
        width: 100%;
    }

    a {
        text-decoration: underline;
    }

    .pointer {
        cursor: pointer;
    }

    .close-cookies {
        cursor: pointer;
    }

    @include media-breakpoint-down(md) {
        right: 1rem;

        > div:first-child {
            width: 100%;
        }
    }
}

.react-datepicker {
    font-family: $font-family-base;

    &__day {

        &--keyboard-selected,
        &--selected {
            background: $primary !important;
        }

        &--disabled {
        }
    }
}

.circle100 {
    border-radius: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container {
    position: relative;
}

.image {
    opacity: 1;
    display: block;
    backface-visibility: hidden;
}

.fileicon {
    opacity: 1;
    display: block;
    backface-visibility: hidden;
}

.image {
    opacity: 1;
    display: block;
    backface-visibility: hidden;
}

.remove {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
}

.image-container:hover .image {
    opacity: 0.5;
}

.image-container:hover .fileicon {
    opacity: 0.5;
}

.image-container:hover .remove {
    opacity: 1;
}

.check-grid {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #2b3940 !important;
    font-size: 16px;
    color: inherit;

    &::before {
        content: "\f0c8";
        font-weight: 400;
        font-family: "Font Awesome 5 Free";
        display: inline-block;
        color: #7e8989;
        margin-right: 11px;
        margin-top: 2px;
    }

    &.active {
        color: #2b3940 !important;
        font-weight: 600;

        &::before {
            content: "\f14a";
            font-weight: 900;
            color: #00b074;
        }
    }
}

.ql-container {
   min-height:200px;
    height: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ql-editor {
    height: 200px;
    flex: 1;
    overflow-y: scroll;
    width: 100%;
}
.grayButton {
    padding: 5px 30px;
    border: 1px solid gray;
    border-radius: 5px;
    -webkit-box-shadow: inset 1px 6px 12px lightgray, inset -1px -10px 5px lightgray, 1px 2px 1px lightgray;
    -moz-box-shadow: inset 1px 6px 12px lightgray, inset -1px -10px 5px lightgray, 1px 2px 1px darkgray;
    box-shadow: inset 1px 6px 12px lightgray, inset -1px -10px 5px lightgray, 1px 2px 1px darkgray;
    background-color: gray;
    color: white;
    text-shadow: 1px 1px 1px black;

    
}
.loaderbase {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: gray;
    z-index: 9999999999;
    opacity: .4;
}


 .pos-abs-center-load {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 1;
}
.pos-bg {
    
    opacity: 0.5;
}
.graycheck {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #2b3940 !important;
    font-size: 12px;
    color: inherit;

    &::before {
        content: "\f0c8";
        font-weight: 600;
        font-family: "Font Awesome 5 Free";
        display: inline-block;
        color: gray;
        margin-right: 11px;
        margin-top: 2px;
    }}


.form-control {
    // Placeholder
    &::placeholder {
        color: $input-placeholder-color;
        font-size: 13px;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: .6;
    }
}

.message-textbox {
    min-height: 30px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    bottom: 0;

    @media only screen and (max-width: 600px) {
        bottom: 80px;
    }
}
.messagebox-height {
    height: 70vh;

    @media only screen and (max-width: 600px) {
        height: 65vh;
    }
}
.box-width-80 {
    width: 80%;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
}
.message-box {
    position: absolute;
}

.movingLoader {
    animation: animateBg 2s infinite linear;
    opacity:.7;
    background-color: #fff;
    background-image: linear-gradient(90deg, #d3d3d3,#bdbdbd,#d3d3d3,#bdbdbd);
    background-size: 300% 100%;
}

@keyframes animateBg {
    0% {
        background-position: 100% 0;
      
    }

    100% {
        background-position: 0 0;
    }
}
.ellipsis{
display: -webkit-box;

-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
line-height: 1.625; /* as per desire */

}