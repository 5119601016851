$white:    #fff ;
$black:    #000;


$green: #6abf16;// #00b074;
$green-2: #569d11;
$violet: #9c5fff;
$blue: #4743db;
$indigo: #5877be;
$denim-blue: #1a5aeb;
$dodger-blue: #336ef7;
$dodger-blue-2: #4D83FF;
$eastern-blue: #2397af;
$spray-blue: #65efe3;
$turquoise-blue: #50D7E5;
$shamrock: #25ce95;
$coral: #fa8557;
$outrageous-orange: #fa6032;
$orange: #fa5f1c;
$red: #f71e3f;
$red-2: #FC3F3F;
$yellow: #ffb300;
$sun-yellow: #facf32;
$casablanca: #f8c546;
$squeeze: #EDF8F5;
$athens-gray: #F4F5F8;
$concrete : #F2F2F2;
$space-black: #2b3940; 
$nevada-gray: #6b6e6f;
$smoke: #7e8989;
$allports: #0070ac;
$poppy: #BE392B;
$marino: #4064AD;
$polar : #F3F9FC;
$egg-blue : #02BFD5;
$conch : #D2DCD6;


$pink: #fc4980;
$regent-blue: #b0d5e8;
$mercury: #e5e5e5;
$ebony-clay: #1d292e;  // newly added
$hit-gray: #adb4b7;  // newly added
$gallery: #f0f0f0;  // newly added
$heliotrope: #9C5FFF;  // newly added


$primary:       $green;
$secondary:     $blue;
$headings-color: var(--color-headings);

